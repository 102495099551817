import React, { useEffect, useMemo, useState } from "react";
import { Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import Table from "../../components/table/Table";
import { useNavigate, useParams } from "react-router-dom";
import services from "../../services";
import { toast } from "react-hot-toast";
import { monthOptions, yearOptions } from "../../Utility/constant";
import moment from "moment";
import { calculateHours, downloadExcel } from "../../Utility/utils";
import AttendanceHeader from "./AttendanceHeader";
function Attendance() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [attendance, setAttendance] = useState([]);
  const attendanceColumns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "In",
        accessor: "in",
      },
      {
        Header: "Out",
        accessor: "out",
      },
      {
        Header: "Hours",
        accessor: "hours",
      },
    ],
    []
  );

  // useEffects =========================================
  useEffect(() => {
    setLoading(true);
    services
      .getUserNames(id)
      .then((res) => {
        const data = res?.data;
        if (data && data!==null && data !== undefined && data?.length > 0  ) {
          setUsers(data);
          setValue(data[0]);
        }
        setMonth(monthOptions[0]);
        setYear(yearOptions[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err)
        toast.error("Something Went Wrong");
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (month !== null && year !== null && value !== null) {
      setLoading(true);
      services
        .getAttendance(month?.value, year?.value, value?.ix)
        .then((res) => {
          if (res?.data?.length > 0) {
            let data = GetTableDateFormatted(res?.data);
            setLoading(false);
            setAttendance(data);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error(err);
        });
    }
  }, [month, year, value]);

  // function to format table data according to generic table of MUI
  function GetTableDateFormatted(data) {
    const res = data.map((row, Day) => {
      if (Day <= 9) Day = "0" + Day; 
      let date = "" + year?.value + "-" + month?.value + "-" + Day;
      if (row.length === 0)
        return {
          date: date,
          name: value?.label,
          in: "-",
          out: "-",
          hours: "-",
        };
      else {
        let ins = row[0];
        let d1 = moment(ins).format("hh:mm:ss a");
        let hours = calculateHours(row);
        if (row.length % 2 === 0)
        {
          var out = row[row?.length - 1];
        } 
        else {
          var out = '';
          hours=0;
        }
        let d2 = (out === '')? '-': moment(out).format("hh:mm:ss a");
        return {
          date: date,
          name: value?.label,
          in: d1,
          out: d2,
          hours: hours,
        };
      }
    });
    res.shift();
    return res;
  }

  if (loading) return <CircularProgress />; 
  return (
    <>
      <Grid className="add-user-button-cont attendance-page">
        <Button
          className="face-detect-back-button"
          onClick={() => navigate("/dashboard/building")}
          variant="contained"
        >
          Back
        </Button>
        {loading === false && users?.length !== 0 && (
          <Button
            onClick={() => downloadExcel(attendance, value, month, year)}
            variant="contained"
          >
            Export
          </Button>
        )}
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {loading === false && users?.length === 0 ? (
          <Typography
          className="no-record-class "
          component="h3"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ textAlign: 'center' }}
          >
          No Record
        </Typography>
        ) : (
          <>
            <AttendanceHeader
              users={users}
              month={month}
              setMonth={setMonth}
              value={value}
              setValue={setValue}
              attendance={attendance}
              year={year}
              setYear={setYear}
            />
            <Table columns={attendanceColumns} data={attendance} />
          </>
        )}
      </Paper>
    </>
  );
}

export default Attendance;


