import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import CloseIcon from "@mui/icons-material/Close";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { usePagination, useTable } from "react-table";
import MuiDialog from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableContainer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import Dialog from "../../components/dialog/Dialog";
import services from "../../services";
import { toast } from "react-hot-toast";
import { useTheme } from "@emotion/react";

function BuildingUserTable({ columns, data, setIsUserAdded }) {
  // Use the state and functions returned from useTable to build your UI
  const [UserImgOpen, setUserImgOpen] = useState(null);
  const [open, setOpen] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    usePagination
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
    gotoPage(0);
  };

  function isValidDate(date) {
    return (
      date &&
      Object.prototype.toString.call(date) === "[object Date]" &&
      !isNaN(date)
    );
  }

  const resendUser = (cell) => {
    const userId = cell.row.original.ix;
    if (userId === undefined) return;

    services
      .resendBuildingUser(userId)
      .then((res) => {
        if (res.status == 500) {
          toast.error("Something went wrong. Please try again.");
          return false;
        }
        toast.success("Payload has been sent successfully.");
        setIsUserAdded("added");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong. Please try again.");
      });
  };

  const handlePreview = (cell) => {
    setUserImgOpen(cell.value);
  };

  const getColumns = (cell) => {
    if (cell.column.id === "photourl") {
      return (
        <Button onClick={() => handlePreview(cell)} variant="contained">
          Preview
        </Button>
      );
    } else if (cell.column.id === "actions") {
      return (
        <>
          <Button onClick={() => handleDeleteSubmit(cell)} variant="contained">
            Delete{" "}
          </Button>
          <Button onClick={() => resendUser(cell)} variant="contained">
            Resend
          </Button>
        </>
      );
    } else if (isValidDate(new Date(cell.value)) && isNaN(cell.value)) {
      return moment(cell.value).format("DD.MM.YYYY HH:mm");
    } else {
      return cell.value;
    }
  };
  const handleDeleteSubmit = (cell) => {
    setOpen(cell);
  };
  const handleClose = () => {
    setOpen(null);
  };
  const deleteUser = () => {
    const userId = open.row.original.ix;
    if (userId === undefined) return;
    services
      .deleteBuildingUser(userId)
      .then((res) => {
        if (res.status == 500) {
          toast.error("Something went wrong. Please try again.");
          return false;
        }
        setOpen(null);
        toast.success("User deleted successfully.");
        setIsUserAdded("added");
      })
      .catch((err) => {
        console.log(err);
        setOpen(null);
        toast.error("Something went wrong. Please try again.");
      });
  };
  // Render the UI for your table
  return (
    <>
      <TableContainer>
        <MuiTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {data.length !== 0 ? (
            <TableBody>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          className={
                            cell.column.id === "actions"
                              ? "user-action-button-container"
                              : ""
                          }
                        >
                          {getColumns(cell)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <div className="no-record-wrapper">
                <Typography
                  className="no-record-class "
                  component="h3"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  No Record
                </Typography>
              </div>
            </TableBody>
          )}
        </MuiTable>
        {data.length !== 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
      <Dialog
        header={"Profile"}
        open={UserImgOpen}
        setOpen={setUserImgOpen}
        type="userImg"
      >
        <img src={UserImgOpen} alt="Image Not Found" />
      </Dialog>
      <MuiDialog
        fullScreen={fullScreen}
        open={open == null ? false : true}
        maxWidth={"md"}
        fullWidth={true}
        onClose={handleClose}
        className="delete-confirmation"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>
          <DialogContentText>You want to delete this user.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button onClick={deleteUser} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </MuiDialog>
    </>
  );
}

export default BuildingUserTable;
