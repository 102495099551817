const now = new Date();
const years = now.getFullYear();

// options for month dropdown on attendance page
export const monthOptions = [
    {
      label: "January",
      value: '01',
    },
    {
      label: "February",
      value: '02',
    },
    {
      label: "March",
      value: '03',
    },
    {
      label: "April",
      value: '04',
    },
    {
      label: "May",
      value: '05',
    },
    {
      label: "June",
      value: '06',
    },
    {
      label: "July",
      value: '07',
    },
    {
      label: "August",
      value: '08',
    },
    {
      label: "September",
      value: '09',
    },
    {
      label: "October",
      value: '10',
    },
    {
      label: "November",
      value: '11',
    },
    {
      label: "December",
      value:'12',
    },
];

  // option for year dropdown on attendance page
export  const yearOptions = [
    {
      value:Number(years),
      label:years
    },
    {
      value:Number(years-1),
      label:years-1
    },
    {
      value:Number(years-2),
      label:years-2
    }
]