import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Dialog from "../../components/dialog/Dialog"
import AddAppartmentForm from "./AddAppartmentForm";
import AppartmentListData from "./AppartmentList";
import { toast } from "react-hot-toast";
import Services from "../../services";


function AppartmentList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [addAppartmentOpen, setAddAppartmentOpen] = useState(false);
  const [isAppartmentAdded,setIsAppartmentAdded] = useState()
  const [isAppartmentUpdated,setIsAppartmentUpdated] = useState()
  const [appartment, setAppartment] = useState([]);
  const [loading, setLoading] = useState(false)


  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "ix",
      },
      {
        Header: "Apartment Id",
        accessor: "appartment",
      },
      {
        Header: "Name",
        accessor: "name",
      },

      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
      
    ],
    []
  );

  useEffect(() => {
    Services.getAppartment(id).then((res) => {
      setLoading(true)
      if (res) {
        setAppartment(res);
        setLoading(false)
      } else {
        toast.error("No apartments found for the given ID");
        setLoading(false)
      }
    });
  }, [isAppartmentAdded, isAppartmentUpdated]);


  if (loading) return <CircularProgress />;
  return (
    <>
      <Grid className="add-user-button-cont attendance-page">
        <Button
          className="face-detect-back-button"
          onClick={() => navigate("/dashboard/building")}
          variant="contained"
        >
          Back
        </Button>
        <Button onClick={() => setAddAppartmentOpen(true)}  variant="contained">
          Add Apartment
        </Button>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AppartmentListData
         columns={columns}
          data={appartment || []} buildingId={id} />

      </Paper>
      <Dialog
        header={"Add Apartment"}
        open={addAppartmentOpen === false ? false : true}
        setOpen={setAddAppartmentOpen}
        type="addAppartment"
      >
        <AddAppartmentForm
          BuildingId={id}
          setIsAppartmentAdded={setIsAppartmentAdded}
          setIsAppartmentUpdated={setIsAppartmentUpdated}
          setAddAppartmentOpen={setAddAppartmentOpen}
        />
      </Dialog>
    </>
  );
}

export default AppartmentList;





