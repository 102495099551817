import { toast } from "react-hot-toast";
import * as XLSX from "xlsx";

// function to get time from seconds
export const getTimefromSeconds = (duration) => {
  var seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  return hours + ":" + minutes + ":" + seconds;
};

// function to calculate hours between dates
export const calculateHours = (row) => {
  let totalTime = 0;
  if (row.length <= 1) return "";
  for (let i = 1; i < row.length; i += 2) {
    let startDate = new Date(row[i - 1]);
    let endDate = new Date(row[i]);
    const diffInMilliseconds = endDate - startDate;
    totalTime += diffInMilliseconds;
  }
  return getTimefromSeconds(totalTime);
};

// function to download excel file through json data
export const downloadExcel = (data, value, month, year) => {
  if (data.length == 0) {
    toast.error("No data Found");
    return;
  }
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  let filename = data[0]?.name
    ? `${value.label + "_" + month.value + "_" + year.value}.xlsx`
    : "DataSheet.xlsx";
  XLSX.writeFile(workbook, filename);
};

export const getDefaultPropsForDropdown = (value) => {
  return {
    options: value,
    getOptionLabel: (option) => option.label,
  };
};
