import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Button, TextField } from "@mui/material";
import services from "../../services";
import InputLabel from "@mui/material/InputLabel";
import { useParams } from "react-router-dom";

const validationSchema = Yup.object().shape({
  user: Yup.string("Enter your email").required("User is required"),
  worker_no: Yup.string("Enter your password").required(
    "Worker Number is required"
  ),
  profile: Yup.mixed().required("Profile photo is Required"),
});
// import withReactContent from 'sweetalert2-react-content'
export default function AdduserForm({
  setAddUserOpen,
  setIsUserAdded,
  BuildingUsers,
}) {
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      user: "",
      worker_no: "",
      profile: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (BuildingUsers.some((e) => e.workerno === values.worker_no)) {
        toast.error("Worker Number should be unique!");
        return;
      }
      const data = {
        name: values.user,
        workerno: values.worker_no,
        building_id: id,
        profile: values.profile,
      };
      services
        .addBuildingUser(data)
        .then((res) => {
          if (res.status == 413) {
            toast.error("Image Too Big!");
          } else {
            toast.success("User Successfully Added");
            setIsUserAdded("added");
          }
          setAddUserOpen(false);
        })
        .catch((err) => {
          toast.error("Error");
          console.log(err);
        });
    },
  });

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  return (
    <form className="add-user-form" onSubmit={formik.handleSubmit}>
      <div>
        <InputLabel for="profile">Profile Photo</InputLabel>
        <TextField
          fullWidth
          id="profile"
          name="profile"
          type="file"
          className="img-file"
          onChange={(event) => {
            getBase64(event.currentTarget.files[0]).then((res) =>
              formik.setFieldValue("profile", res)
            );
          }}
          error={formik.touched.profile && Boolean(formik.errors.profile)}
          helperText={formik.touched.profile && formik.errors.profile}
        />
      </div>
      <div>
        <InputLabel for="user">User</InputLabel>
        <TextField
          fullWidth
          id="user"
          name="user"
          value={formik.values.user}
          onChange={formik.handleChange}
          error={formik.touched.user && Boolean(formik.errors.user)}
          helperText={formik.touched.user && formik.errors.user}
        />
      </div>
      <div>
        <InputLabel for="worker_no">Worker Number</InputLabel>
        <TextField
          fullWidth
          id="worker_no"
          name="worker_no"
          type="number"
          value={formik.values.worker_no}
          onChange={formik.handleChange}
          error={formik.touched.worker_no && Boolean(formik.errors.worker_no)}
          helperText={formik.touched.worker_no && formik.errors.worker_no}
        />
      </div>
      <Button color="primary" variant="contained" fullWidth type="submit">
        Submit
      </Button>
    </form>
  );
}
