import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Dialog from "../../components/dialog/Dialog"
import ProximityForm from "./ProximityForm";
import ProximityListData from "./ProximityList";
import { toast } from "react-hot-toast";
import Services from "../../services";


function ProximityList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [addProximityOpen, setAddProximityOpen] = useState(false);
  const [isProximityAdded,setIsProximityAdded] = useState()
//   const [isAppartmentUpdated,setIsAppartmentUpdated] = useState()
  const [proximity, setProximity] = useState([]);
  const [loading, setLoading] = useState(false)

  
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "ix",
      },
      {
        Header: "Serial",
        accessor: "serial",
      },
      {
        Header: "Name",
        accessor: "name",
      },

      {
        Header: "Entrance",
        accessor: "entrance",
      },
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
      
    ],
    []
  );

  useEffect(() => {
    Services.getProximity(id).then((res) => {
      setLoading(true)
      if (res) {    
        setProximity(res);
        setLoading(false)
      } else {
        toast.error("No proximity found");
        setLoading(false)
      }
    });
  }, [isProximityAdded]);


  if (loading) return <CircularProgress />;
  return (
    <>
      <Grid className="add-user-button-cont attendance-page">
        <Button
          className="face-detect-back-button"
          onClick={() => navigate("/dashboard/building")}
          variant="contained"
        >
          Back
        </Button>
        <Button onClick={() => setAddProximityOpen(true)} variant="contained">
          Add  Proximity
        </Button>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ProximityListData
         columns={columns}
          data={proximity || []} />
          

      </Paper>
      <Dialog
        header={"Add Proximity"}
        open={addProximityOpen === false ? false : true}
        setOpen={setAddProximityOpen}
        type="addProximity"
      >
        <ProximityForm
          // BuildingId={id}
          setIsProximityAdded={setIsProximityAdded}
          // setIsAppartmentUpdated={setIsAppartmentUpdated}
          setLoading={setLoading}
          loading={loading}
          setAddProximityOpen={setAddProximityOpen}
        />
      </Dialog>
    </>
  );
}

export default ProximityList;





