import { Routes, Route } from "react-router-dom";

import SignIn from "./Pages/Signin";
import Layouts from "./Layouts";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./App.css";
import axios from "axios";
import { useEffect } from "react";

function App() {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="dashboard/*" element={<Layouts header="Dashboard" />} />
    </Routes>
  );
}

export default App;
