import { useState, useEffect, useMemo } from "react"; 
import CssBaseline from "@mui/material/CssBaseline";
import Table from "../../components/table/Table";
import Services from "../../services";
import Dialog from "../../components/dialog/Dialog";
import { Chip, Paper } from "@mui/material"; 
export default function Building() {
  const [building, setBuilding] = useState([]);
  const [open, setOpen] = useState(false);
  const [tenant, setTenant] = useState([]);
  const [tech, setTech] = useState([]);
  const [techOpen, setTechOpen] = useState(false);
  const columns = useMemo(
    () => [
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "City",
        accessor: "city",
      },
      {
        Header: "Max Apartments",
        accessor: "maxappartments",
      },
      {
        Header: "Max Entrance",
        accessor: "maxentrance",
      },
      {
        Header: "Allow Guest",
        accessor: "allowguest",
        Cell: ({ value }) => {
          return (
            <>
              {value ? (
                <Chip label="Yes" size="small" color="primary" />
              ) : (
                <Chip label="No" size="small" color="error" />
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const tenantColumns = useMemo(
    () => [
      {
        Header: "Apartment",
        accessor: "appartment",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Last Name",
        accessor: "lastname",
      },
      {
        Header: "Cellular",
        accessor: "cellular",
      },
      {
        Header: "Authentication Code",
        accessor: "authcode",
      },
    ],
    []
  );

  const techColumns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Cellular",
        accessor: "cellular",
      },
      {
        Header: "Password",
        accessor: "password",
      },
    ],
    []
  );

  useEffect(() => {
    Services.getBuilding().then((res) => {
      setBuilding(res);
    });
  }, []);

  const showTenant = (data) => {
    setTenant([]);
    Services.getTenant(data.serial).then((res) => {
      setTenant(res);
      setOpen(true);
    }).catch(err => {
      console.log(err)
    });
  };
  const showTech = (data) => {
    setTech([]);
    Services.getTech(data.serial).then((res) => {
      setTech(res);
      setTechOpen(true);
    }).catch(err => {
      console.log(err)
    });
  };

  return ( 
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      > 
        <CssBaseline />
        <Table
          columns={columns}
          data={building}
          showAction={showTenant}
          showTech={showTech}
        />
        <Dialog header={"Tenant View"} open={open} setOpen={setOpen} type='tenant'>
          <Table columns={tenantColumns} data={tenant} />
        </Dialog>
        <Dialog header={"Tech View"} open={techOpen} setOpen={setTechOpen} type='tech'>
          <Table columns={techColumns} data={tech} />
        </Dialog>
      </Paper> 
  );
}
