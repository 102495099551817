import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import CloseIcon from "@mui/icons-material/Close";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { usePagination, useTable } from "react-table";
import MuiDialog from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableContainer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import Dialog from "../../components/dialog/Dialog";
import services from "../../services";
import { toast } from "react-hot-toast";
import ProximityForm from "./ProximityForm";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

export default function ProximityListData({ columns, data }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(null);
  const [filteredData, setFilteredData] = useState();
  const navigate = useNavigate();

  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    usePagination
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
    gotoPage(0);
  };
  const getColumns = (cell) => {
    if (cell.column.id === "actions") {
      return (
        <>
          <Button onClick={() => handleEditSubmit(cell)} variant="contained">
            Edit
          </Button>
        </>
      );
    } else {
      return cell.value;
    }
  };
  const handleEditSubmit = (cell) => {
    const userId = cell?.row?.original?.ix;
    setOpen(cell);
    const filteredData = data.find((data) => data.ix === userId);
    setFilteredData(filteredData);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <TableContainer>
        <MuiTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          {data.length !== 0 ? (
            <TableBody>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          {...cell.getCellProps()}
                          className={
                            cell.column.id === "actions"
                              ? "user-action-button-container"
                              : ""
                          }
                        >
                          {getColumns(cell)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <div className="no-record-wrapper">
                <Typography
                  className="no-record-class "
                  component="h3"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                >
                  No Record
                </Typography>
              </div>
            </TableBody>
          )}
        </MuiTable>
        {data.length !== 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={pageSize}
            page={pageIndex}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>

      <MuiDialog
        fullScreen={fullScreen}
        open={open == null ? false : true}
        maxWidth={"md"}
        fullWidth={true}
        onClose={handleClose}
        className="delete-confirmation  rozcomapp-custom-modal"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogActions>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          ></IconButton>
          <ProximityForm filteredData={filteredData} setOpen={setOpen} />
        </DialogActions>
        <Button autoFocus onClick={handleClose}>
          cancel
        </Button>
      </MuiDialog>
    </>
  );
}
