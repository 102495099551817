import React, { useState } from "react";
import toast from "react-hot-toast";
import { Button, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useParams } from "react-router-dom";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import services from "../../services";

export default function AddTenantForm({
  setAddTenantOpen,
  setIsTenantAdded,
  setEditData,
  BuildingId,
  appartmentId,
  filteredData,
  setOpen,
  setFilteredData,
  setLoading,
  loading,
}) {
  const [name, setName] = useState(
    filteredData?.name ? filteredData?.name : ""
  );
  const [lastName, setLastName] = useState(
    filteredData?.lastname ? filteredData?.lastname : ""
  );
  const [cellular, setCellular] = useState(
    filteredData?.cellular ? filteredData?.cellular : ""
  );
  const [entrance, setEntrance] = useState(
    filteredData?.entrance ? filteredData?.entrance : "0"
  );

  const handleRadioChange = (event) => {
    setEntrance(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const regex = /^[0-9]+$/;

    if (!name || !lastName || !cellular) {
      toast.error("All fields are required");

      return;
    } else if (!regex.test(cellular)) {
      toast.error("Cellular should be only number");

      return;
    } else if (cellular.toString().length > 10) {
      toast.error("Cellular should not greater than 10");

      return;
    }

    const formData = {
      name: name,
      lastName: lastName,
      cellular: cellular,
      entrance: entrance,
      appartmentId: appartmentId, //appartment id
      buildingid: BuildingId,
    };

    if (filteredData?.ix) {
      services
        .editTenant(formData, filteredData?.ix)
        .then((res) => {
          toast.success(res.message);
          setOpen(null);
          window.location.reload();
          setEditData(res.message);
          setIsTenantAdded(res);
        })
        .catch((err) => console.log(err.response));
    } else {
      setLoading(true);
      services
        .addTenant(formData)
        .then((res) => {
          
          if (res.status === 200) {
            toast.success(res.message);
          } else if (res.status === 422) {
            toast.error(res.data.message);
          } else if (res.status === 400) {
            toast.error(res.data.Error);
          }

          setIsTenantAdded(res);
          setName("");
          setLastName("");
          setCellular("");
          setCellular(0);
          setLoading(false);
          setAddTenantOpen(false);
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    }
  };

  return (
    <form className="add-user-form" onSubmit={handleSubmit}>
      <div>
        <InputLabel for="profile">Name</InputLabel>
        <TextField
          fullWidth
          name="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div>
        <InputLabel for="user">Last Name</InputLabel>
        <TextField
          fullWidth
          name="lastName"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>

      <div>
        <InputLabel for="user">Cellular</InputLabel>
        <TextField
          fullWidth
          name="cellular"
          type="text"
          value={cellular}
          onChange={(e) => setCellular(e.target.value)}
        />
      </div>
      <div>
        <InputLabel>Entrance</InputLabel>
        <RadioGroup
          row
          name="isTrue"
          value={entrance}
          onChange={handleRadioChange}
        >
          <FormControlLabel value="1" control={<Radio />} label="True" />
          <FormControlLabel value="0" control={<Radio />} label="False" />
        </RadioGroup>
      </div>
      <Button
        disabled={loading}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
}
