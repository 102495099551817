import * as React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Dialog from "../../components/dialog/Dialog";
import Services from "../../services";
import { useEffect, useState } from "react";
import AdduserForm from "./AdduserForm";
import BuildingUserTable from "./BuildingUserTable";

export default function BuildingUsers() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [faceDetect, setFaceDetect] = useState([]);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [isUserAdded, setIsUserAdded] = useState(null);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "ix",
      },
      {
        Header: "User",
        accessor: "name",
      },
      {
        Header: "Worker Number",
        accessor: "workerno",
      },
      {
        Header: "Photo",
        accessor: "photourl",
      },
      {
        Header: "Created At",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  useEffect(() => {
    if (isUserAdded === null || isUserAdded === "added") {
      Services.getBuildingUsers(id)
        .then((res) => {
          setFaceDetect(res.data);
          setIsUserAdded("closed");
        })
        .catch((err) => {
          console.log(err);
          setIsUserAdded("closed");
        });
    }
  }, [isUserAdded]);

  return (
    <>
      <Grid className="add-user-button-cont">
        <Button
          className="face-detect-back-button"
          onClick={() => navigate("/dashboard/building")}
          variant="contained"
        >
          Back
        </Button>
        <Button onClick={() => setAddUserOpen(true)} variant="contained">
          Add User
        </Button>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BuildingUserTable
          columns={columns}
          data={faceDetect || []}
          setIsUserAdded={setIsUserAdded}
        />
      </Paper>
      <Dialog
        header={"Add User"}
        open={addUserOpen === false ? false : true}
        setOpen={setAddUserOpen}
        type="addUser"
      >
        <AdduserForm
          BuildingUsers={faceDetect}
          setIsUserAdded={setIsUserAdded}
          setAddUserOpen={setAddUserOpen}
        />
      </Dialog>
    </>
  );
}
