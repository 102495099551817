import React, { useState } from "react";
import toast from "react-hot-toast";
import { Button, TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useParams } from "react-router-dom";
import services from "../../services";

// import withReactContent from 'sweetalert2-react-content'
export default function AddAppartmentForm({
  setAddAppartmentOpen,
  setIsAppartmentAdded,
  setIsAppartmentUpdated,
  BuildingId,
  filteredData,
  setOpen,
  setFilteredData,
}) {
  const { id } = useParams();
  const [name, setName] = useState(filteredData?.name ? filteredData.name : "");
  const [appartmentId, setAppartmentId] = useState(
    filteredData?.appartment ? filteredData?.appartment : ""
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const regex = /^[0-9]+$/;

    if (!name || !appartmentId) {
      toast.error("All fields are required");
      return;
    } else if (!regex.test(appartmentId)) {
      toast.error("Id should be only number");
      return;
    }

    const formData = {
      name: name,
      appartment: appartmentId,
      buildingid: BuildingId,
    };

    if (filteredData?.ix) {
      services
        .editAppartment(formData, filteredData?.ix)
        .then((res) => {
          toast.success(res.message);
          setOpen(null);
          window.location.reload();
          setIsAppartmentUpdated(res);
        })

        .catch((err) => console.log(err.response));
    } else {
      services
        .addAppartment(formData)
        .then((res) => {
          toast.success(res.message);
          setIsAppartmentAdded(res);
          setName("");
          setAppartmentId("");
          setAddAppartmentOpen(false);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  };

  return (
    <form className="add-user-form" onSubmit={handleSubmit}>
      <div>
        <InputLabel for="profile">Name</InputLabel>
        <TextField
          fullWidth
          name="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div>
        <InputLabel for="user">Apartment Id</InputLabel>
        <TextField
          fullWidth
          name="id"
          type="text"
          value={appartmentId}
          onChange={(e) => setAppartmentId(e.target.value)}
        />
      </div>
      <Button color="primary" variant="contained" fullWidth type="submit">
        Submit
      </Button>
    </form>
  );
}
