import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { usePagination, useTable } from "react-table";
import { Button, TableContainer } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Table({ columns, data, showAction, showTech }) {
  // Use the state and functions returned from useTable to build your UI
  if (data === undefined) data = [];
  const navigate = useNavigate();
  const {
    getTableProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    usePagination
  );

  const userRole = localStorage.getItem("role");

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(event.target.value);
    gotoPage(0);
  };
  // Render the UI for your table
  return (
    <TableContainer>
      <MuiTable {...getTableProps()}>
        <TableHead>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableCell {...column.getHeaderProps()}>
                  {column.render("Header")}
                </TableCell>
              ))}
              {showAction && <TableCell>Actions</TableCell>}
            </TableRow>
          ))}
        </TableHead>
        {data && data !== undefined && data?.length !== 0 && (
          <TableBody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                  {showAction && (
                    <TableCell
                      className="building-table-button-wrapper"
                      sx={{ display: "flex", flexWrap: "wrap", gap: "0.5rem" }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => showAction(row.original)}
                      >
                        Tenant
                      </Button>
                      {userRole === "3" && (
                        <>
                          <Button
                            variant="outlined"
                            onClick={() => showTech(row.original)}
                            sx={{ marginLeft: 1 }}
                          >
                            Tech
                          </Button>

                          <Button
                            variant="outlined"
                            onClick={() =>
                              navigate(
                                `/dashboard/building/user/${row.original.ix}?name=${row.original.address}`
                              )
                            }
                            sx={{ marginLeft: 1 }}
                          >
                            Face Detect
                          </Button>

                          <Button
                            variant="contained"
                            onClick={() =>
                              navigate(
                                `/dashboard/building/attendance/${row.original.ix}?name=${row.original.address}`
                              )
                            }
                            sx={{ marginLeft: 1 }}
                          >
                            Attendance
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() =>
                              navigate(
                                `/dashboard/building/${row.original.ix}/appartments`
                              )
                            }
                            sx={{ marginLeft: 1 }}
                          >
                            Apartment
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() =>
                              navigate(
                                `/dashboard/building/${row.original.ix}/proximity`
                              )
                            }
                            sx={{ marginLeft: 1 }}
                          >
                            Proximity Cards
                          </Button>

                          <Button
                            variant="contained"
                            onClick={() =>
                              navigate(
                                `/dashboard/building/${row.original.ix}/nfc`
                              )
                            }
                            sx={{ marginLeft: 1 }}
                          >
                            NFC
                          </Button>
                        </>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </MuiTable>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={pageSize}
        page={pageIndex}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default Table;
