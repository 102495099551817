import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import React from 'react'
import {   getDefaultPropsForDropdown } from '../../Utility/utils';
import { monthOptions, yearOptions } from '../../Utility/constant';

function AttendanceHeader({users, month , setMonth , year , setYear , value , setValue}) { 
 
  // DataProps for select dropdown ======================   
  const defaultProps = getDefaultPropsForDropdown(users);
  const defaultPropsMonths = getDefaultPropsForDropdown(monthOptions);
  const defaultPropsYear = getDefaultPropsForDropdown(yearOptions);  
  return ( 
            <Grid
              className="attendance-header"
              sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}
            >
              <Autocomplete
                {...defaultPropsMonths}
                id="controlled-demo1"
                size="small"
                value={month}
                onChange={(event, newValue) => {
                  setMonth(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    className="attendance-user"
                    {...params}
                    label="Month"
                    variant="outlined"
                  />
                )}
              />
              <Autocomplete
                {...defaultPropsYear}
                id="controlled-demo2"
                size="small"
                value={year}
                onChange={(event, newValue) => {
                  setYear(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    className="attendance-user"
                    {...params}
                    label="Year"
                    variant="outlined"
                  />
                )}
              />
              <Autocomplete
                {...defaultProps}
                id="controlled-demo3"
                size="small"
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    className="attendance-user"
                    {...params}
                    label="Users"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            
  )
}

export default AttendanceHeader
