import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Dialog from "../../components/dialog/Dialog"
import NfcForm from "./NfcForm";
import NfcListData from "./NfcList";
import { toast } from "react-hot-toast";
import Services from "../../services";


function NfcList() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [addNfcOpen, setAddNfcOpen] = useState(false);
  const [isNfcAdded,setIsNfcAdded] = useState()
//   const [isAppartmentUpdated,setIsAppartmentUpdated] = useState()
  const [nfc, setNfc] = useState([]);
  const [loading, setLoading] = useState(false)

  
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "ix",
      },
      {
        Header: "Serial",
        accessor: "serial",
      },
      {
        Header: "Name",
        accessor: "name",
      },

      {
        Header: "Panel Serial",
        accessor: "panel_serial",
      },
      {
        Header: "Code",
        accessor: "code",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
      
    ],
    []
  );

  useEffect(() => {
    setLoading(true)
    Services.getNfc(id).then((res) => {
      if (res) {    
        setNfc(res);
      } else {
        toast.error("No NFC found");
      }
      setLoading(false)
    });
  }, [isNfcAdded]);


  if (loading) return <CircularProgress />;
  return (
    <>
      <Grid className="add-user-button-cont attendance-page">
        <Button
          className="face-detect-back-button"
          onClick={() => navigate("/dashboard/building")}
          variant="contained"
        >
          Back
        </Button>
        <Button onClick={() => setAddNfcOpen(true)} variant="contained">
          Add  NFC
        </Button>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <NfcListData
         columns={columns}
          data={nfc || []} />
          

      </Paper>
      <Dialog
        header={"Add NFC"}
        open={addNfcOpen === false ? false : true}
        setOpen={setAddNfcOpen}
        type="addNfc"
      >
        <NfcForm
          // BuildingId={id}
          setIsNfcAdded={setIsNfcAdded}
          // setIsAppartmentUpdated={setIsAppartmentUpdated}
          setLoading={setLoading}
          loading={loading}
          setAddNfcOpen={setAddNfcOpen}
        />
      </Dialog>
    </>
  );
}

export default NfcList;





